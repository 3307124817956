<template>
  <dash-page-new
      :title="$t('ViewCertificate')"
      icon="mdi-certificate"

  >

    <template #header_action>
      <v-btn  :color="wsATTENTION" large class="noCaps mb-5" elevation="0"  dark @click="downloadCertificate">
        <v-icon>mdi-download</v-icon>
        {{  $t('Download')  }} Pdf
      </v-btn>
    </template>


    <div class="d-flex justify-center mt-10">

      <v-sheet class="pa-3 pb-1 wsRoundedHalf" :color="wsLIGHTCARD">
        <certificate-viewer
            v-if="block.id"
            :block="block"
            :elements="elements"
        />
      </v-sheet>

    </div>


  </dash-page-new>
</template>

<script>
import certificateViewer from "@/components/pages/westudy/admin/course/courseCertificates/view/certificateViewer";
import {mapActions} from "vuex";

export default {
  name: "viewCertificate",
  components : {
    certificateViewer
  },
  props : {
    uuid : {
      type : String
    }
  },
  data() {
    return {
      block : {},
      elements : []
    }
  },
  watch : {
    uuid() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('certificates' , [
        'GET_COURSE_CERTIFICATE_STUDENT',
        'DOWNLOAD_CERTIFICATE'
    ]),

    async downloadCertificate() {
      let result = await this.DOWNLOAD_CERTIFICATE(this.uuid)
      if ( !result ) {
        return
      }

      var url  = window.URL.createObjectURL(result);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'certificate.pdf';
      document.body.appendChild(a);
      a.click();

    },

    async initPage() {
      let result = await this.GET_COURSE_CERTIFICATE_STUDENT(this.uuid)
      if ( !result ) {
        return
      }
      this.block = result.block
      this.elements = result.elements
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>